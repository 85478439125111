import "./Articles.scss";
import { useEffect, useState } from "react";
import ARTICLES from "./articles.json";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Articles() {
    const articles = ARTICLES;
    const navigate = useNavigate();

    const goToArticle = (articleId: string) => {
        navigate(`/articles/${articleId}`);
    }

    return (
        <div className="page">
            <div className="articles">
                <h1 className="h1">Articles</h1>
                <div className="articles-row">
                    {
                        articles.map(article => {
                            return (
                                <div onClick={() => goToArticle(article.id)} className="article" key={article.id}>
                                    <h2 className="h2">{article.title}</h2>
                                    <h4 className="h4 h4-accent">{article.author}</h4>
                                    <p className="p">{article.date}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Articles;