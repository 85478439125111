import "./Values.scss";

function Values() {
    const values = [
        {
            name: "Diverse Discourse",
            description: "We believe in the beauty of diversity, and our blog reflects just that. From academic pressures to personal triumphs, societal issues to pop culture news, we cover it all. Our writers bring unique insights, ensuring a variety of thoughts and opinions that resonate with every reader."
        },
        {
            name: "Safe Space Committment",
            description: "Elevaid is dedicated to ensuring our platform is a safe space. All students should feel comfortable expressing themselves without fear of judgment. Respect and empathy are the pillars of our values and we actively moderate our content to ensure everyone’s voice is respected. You can choose to post anonymously if you wish to hide your identity."
        },
        {
            name: "Supportive Community",
            description: "You will find here at Elevaid an embracing community with like-minded individuals who care about the same issues as you. Connect, collaborate, and learn from one another as you navigate the ups and downs of student life!"
        },
        {
            name: "Student-Centric",
            description: "This is about you! We understand the unique challenges, dreams, and aspirations you face, and we’re here to support you on your journey!"
        }
    ]

    return (
        <div className="page">
            <div className="our-team">
                <h1 className="h1">Values</h1>
                <div className="team-row">
                    {
                        values.map(value => {
                            return (
                                <div className="person">
                                    <div className="person-content">
                                        <h2 className="h2">{value.name}</h2>
                                        <p className="p">{value.description}</p>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default Values;