import { NavLink } from "react-router-dom";
import "./Footer.scss";

function Footer() {

    return (
        <div className="footer">
            <div className="footer-logo">
                <img src="./elevaid.svg" alt="Elevaid Logo" />
            </div>
            <div className="footer-copyright">
                <p className="p-large">
                    <span>
                        @2023 All Rights Reserved.
                    </span>
                    &nbsp;
                    <span className="p-large-bold p-large-accent">
                        Elevaid, a Nonprofit Corporation
                    </span>
                </p>
            </div>
        </div>
    )
}

export default Footer;