import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import OurTeam from "../OurTeam/OurTeam";
import Articles from "../Articles/Articles";
import Article from "../Article/Article";
import './App.scss';
import Values from "../Values/Values";


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <section className="body">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/values" element={<Values />}></Route>
            <Route path="/our-team" element={<OurTeam />}></Route>
            <Route path="/articles" element={<Articles />}></Route>
            <Route path="/articles/:id" element={<Article />}></Route>
          </Routes>
        </section>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
