import { NavLink } from "react-router-dom";
import "./Home.scss";

function Home() {

    return (
        <div className="page">
            <div className="hero">
                <div className="hero-content">
                    <h1 className="h1">
                        Your Voice Matters
                    </h1>
                    <p className="p">
                        Writing is a tool, an expression and reflection of oneself. Here at Elevaid, we are committed to creating a safe space to speak on topics or issues that matter to you. This is more than just a blog: it’s a community where every voice is valued and every perspective is celebrated.
                    </p>
                    <NavLink to="/our-team">
                        <button className="button button-filled-accent">
                            <p className="p-large">
                                Learn More
                            </p>
                        </button>
                    </NavLink>
                </div>
                <div className="hero-image">
                    <img src="./hero.png" alt="Hero Image" />
                </div>
            </div>
            <div className="join-us">
                <div className="join-us-content">
                    <h1 className="h1">
                        Interested in Joining This Organization?
                    </h1>
                    <p className="p">
                        Click on the Join Us button to fill out a form about yourself if you are interested in joining!
                    </p>
                </div>
                <div className="join-us-button">
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe8VG1uGnzINtlve0T71CHS1gb2ZZywQ1ZIqRjQLFp9lXkeMA/viewform?usp=sf_link">
                        <button className="button button-filled-accent">
                            <div className="p-large">
                                Join Us
                            </div>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home;