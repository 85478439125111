import "./OurTeam.scss";

function OurTeam() {
    const ourTeam = [
        {
            pfp: "./aarav.jpg",
            name: "Aarav Chuttani",
            type: "Co-President",
            description: "My name is Aarav Chuttani and I’m a junior at East Brunswick high school. I’ve always had a passion for helping those less fortunate with any means possible.  I hope to accomplish this through Elevaid. My future aspirations are to pursue a career in business and finance, starting my own venture one day."
        },
        {
            pfp: "./person.jpg",
            name: "Ananya Bhatla",
            type: "Co-President",
            description: "My name is Ananya Bhatla and I’m a freshman at J.P. Stevens High School. From a young age, I had an interest in the medical field as I was exposed to the field and aiding others. I hope to contribute to the community through Elevaid, and understand how I can further take these efforts into my future career."
        },
        {
            pfp: "./brendon.jpg",
            name: "Brendon Law",
            type: "Chief Editor",
            description: "Hey, my name is Brendon Law and i’m a senior in East Brunswick High School. I’ve always had a passion for better understanding others as I feel that sometimes we as people lack empathy for others. I hope through Elevaid I will be able to help people feel heard through our articles. In the future, I hope to become a court attorney"
        },
        {
            pfp: "./som.jpg",
            name: "Som Shrivastava",
            type: "Director of Tech",
            description: "Hey, I’m a junior at East Brunswick High School who loves to design and develop websites.  I became very bored during the pandemic when schools shut down.  I was then introduced to coding by my dad and have found a great passion for it.  Apart from coding, I love playing cricket, tennis, and table tennis."
        }
    ]

    return (
        <div className="page">
            <div className="our-team">
                <h1 className="h1">Our Team</h1>
                <p className="p-large">
                    Elevaid is a student-run blog that values the importance of self expression. We want to create a community that welcomes a diverse group of writers who carry various thoughts and perspectives. Our team ensures the functionality of the community as well as enforcing our key values to ensure that Elevaid continues to be a safe space for students alike.    
                </p>

                <div className="team-row">
                    {
                        ourTeam.map(member => {
                            return (
                                <div className="person">
                                    <img src={member.pfp} alt={member.name} />
                                    <div className="person-content">
                                        <h2 className="h2">{member.name}</h2>
                                        <h4 className="h4 h4-accent">{member.type}</h4>
                                        <p className="p">{member.description}</p>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default OurTeam;