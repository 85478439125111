import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";

function Navbar() {

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    function getHamburgerIcon() {
        if (isNavbarOpen) {
            return (
                <div className="cursor-pointer" onClick={() => { setIsNavbarOpen(!isNavbarOpen) }}>
                    <img src="./hamburger-icon-accent.svg" alt="" />
                </div>
            )
        } else {
            return (
                <div className="cursor-pointer" onClick={() => { setIsNavbarOpen(!isNavbarOpen) }}>
                    <img src="./hamburger-icon-black.svg" alt="" />
                </div>
            )
        }
    }

    function getNavbarLinks() {
        return (
            <div className="navbar-links">
                <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <p className="p-large p-large-bold p-large-black">
                        Home
                    </p>
                </NavLink>
                <NavLink to="/values" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <p className="p-large p-large-bold p-large-black">
                        Values
                    </p>
                </NavLink>
                <NavLink to="/our-team" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <p className="p-large p-large-bold p-large-black">
                        Our Team
                    </p>
                </NavLink>
                <NavLink to="/articles" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <p className="p-large p-large-bold p-large-black">
                        Articles
                    </p>
                </NavLink>
                <button className="button button-filled-accent">
                    <p className="p-large">
                        Donate
                    </p>
                </button>
            </div>
        )
    }

    function getMenu() {
        if (isNavbarOpen) {
            return (
                <div>
                    {getNavbarLinks()}
                </div>
            )
        }
    }

    function getNavbar() {
        if (window.innerWidth > 768) {
            return (
                <div className="navbar">
                    <div className="navbar-logo">
                        <img src="./elevaid.svg" alt="Elevaid Logo" />
                    </div>
                    {getNavbarLinks()}
                </div>
            );
        } else {
            return (
                <div className="navbar">
                    <div className="navbar-logo">
                        <img src="./elevaid.svg" alt="Elevaid Logo" />
                        {getHamburgerIcon()}
                    </div>
                    {getMenu()}
                </div>
            );
        }
    }

    return (
        <div>
            {getNavbar()}
        </div>
    )

    return (
        <div className="navbar">
            <div className="navbar-logo">
                <img src="./elevaid.svg" alt="Elevaid Logo" />
            </div>
            <div className="navbar-links">
                <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <p className="p-large p-large-bold p-large-black">
                        Home
                    </p>
                </NavLink>
                <NavLink to="/our-team" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <p className="p-large p-large-bold p-large-black">
                        Our Team
                    </p>
                </NavLink>
                <button className="button button-filled-accent">
                    <p className="p-large">
                        Donate
                    </p>
                </button>
            </div>
        </div>
    )
}

export default Navbar;