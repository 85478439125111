import "./Article.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import ARTICLES from "./../Articles/articles.json"


function Article() {
    const { id } = useParams(); 
    const articles = ARTICLES;
    const [article, setArticle] = useState({
        title: "",
        author: "",
        date: ""
    });
    const [markdown, setMarkdown] = useState("");

    const markdownData = () => {
        import(`./markdown/${id}.md`)
            .then((res) => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setMarkdown(res))
            })
    }


    useEffect(() => {
        markdownData();
        articles.forEach(article => {
            if (article.id == id) {
                setArticle(article);
            }
        });
    }, [])

    return (
        <div className="page">
            <div className="article">
                <h1 className="h1">{article.title}</h1>
                <h2 className="h2">{article.author}</h2>
                <h2 className="h3">{article.date}</h2>
                <p>
                    <ReactMarkdown children={markdown} remarkPlugins={[]} />;
                </p>
            </div>
        </div>
    )
}

export default Article;